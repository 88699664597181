









import YTPlayer from "@/components/VideoPlayers/YTPlayer.vue"
import MediaAsset from "@/components/Assets/MediaAsset"
import { Media } from "@/components/GroupTeams/Common/SmartMedia.vue"

export default MediaAsset.extend({
  name: "YoutubeAsset",
  components: {
    YTPlayer
  },
  props: {
    videoCode: {
      type: String,
      default: undefined
    },
    interrupt: {
      type: Boolean,
      default: true
    }
  },
  created() {
    if (!this.interrupt) return
    Media.add(this._uid)
  },
  beforeDestroy() {
    if (!this.interrupt) return
    Media.rm(this._uid)
  }
})
