var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("YTPlayer", {
    attrs: {
      sync: _vm.isHostLike,
      videoCode: _vm.videoCode,
      controls: _vm.controls,
    },
    model: {
      value: _vm.state,
      callback: function ($$v) {
        _vm.state = $$v
      },
      expression: "state",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }