<template>
  <div class="video-mission">
    <VideoAsset
      v-if="isVideoLink"
      :src="videoUrl"
      :controls="controls"
      class="video-mission__video-asset"
    />
    <YoutubeAsset
      v-else
      :videoCode="videoUrl"
      :controls="controls"
      class="video-mission__video-asset"
    />
    <v-progress-circular
      class="video-mission__loader"
      indeterminate
      color="#8680ff"
      size="80"
    />
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import isVideo from "is-video"

import VideoAsset from "@/components/Assets/VideoAsset"
import YoutubeAsset from "@/components/Assets/YoutubeAsset"

export default Vue.extend({
  name: "VideoMission",
  components: {
    VideoAsset,
    YoutubeAsset
  },
  props: {
    mission: Object,
    mode: String
  },
  computed: {
    ...mapGetters("auth", ["user", "isHost", "isModerator"]),
    ...mapGetters("group", ["media"]),
    isHostLike() {
      return this.isHost || this.isModerator
    },
    controls() {
      return this.isWatchParty ? this.isHostLike : true
    },
    isWatchParty() {
      return Boolean(this.mission?.watchParty)
    },
    videoUrl() {
      return String(this.mission?.youtube)
    },
    isVideoLink() {
      return isVideo(this.videoUrl)
    }
  },

  mounted() {
    if (this.$store.getters["auth/volume"] < 0.5)
      this.$store.dispatch("soundeffect/updateUserVolume", {
        userID: this.user?.id,
        volume: 0.5
      })
  }
})
</script>

<style lang="scss">
.video-mission {
  width: 100%;
  left: 0;
  top: 16px;
  bottom: 16px;
  position: absolute;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 -17px 48px rgba(15, 15, 17, 0.8), 0 4px 14px rgba(0, 0, 0, 0.32);

  &::before {
    content: "";
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    position: absolute;
    z-index: -1;
  }

  &__video-asset {
    position: relative;
    width: 100%;
    height: 100%;
    .video-asset__player,
    .plyr__video-wrapper,
    .plyr--video {
      position: relative;
      width: 100%;
      height: 100%;
    }
    video {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      object-fit: contain;
    }
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }

  &__tip-icon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__tip {
    padding-top: 8px;
    white-space: nowrap;
    padding-bottom: 8px;
    padding-left: 50px;
    padding-right: 24px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 50px;
    position: absolute;
    left: 50%;
    top: 25%;
    transform: translateX(-50%);
    p {
      margin: 0;
      line-height: 1.5;
      font-size: 16px;
    }
  }
}
</style>
