var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-mission" },
    [
      _vm.isVideoLink
        ? _c("VideoAsset", {
            staticClass: "video-mission__video-asset",
            attrs: { src: _vm.videoUrl, controls: _vm.controls },
          })
        : _c("YoutubeAsset", {
            staticClass: "video-mission__video-asset",
            attrs: { videoCode: _vm.videoUrl, controls: _vm.controls },
          }),
      _c("v-progress-circular", {
        staticClass: "video-mission__loader",
        attrs: { indeterminate: "", color: "#8680ff", size: "80" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }